/* Add this to your CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    font-style: normal;
  }
  @font-face {
    font-family: 'ArsenalBold';
    src: url('/public/fonts/Arsenal-Bold.ttf') format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'ArsenalRegular';
    src: url('/public/fonts/Arsenal-Regular.ttf') format('truetype');
    font-style: normal;
  }
  