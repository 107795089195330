nav {
  position: fixed;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.z-10 {
  z-index: 10;
}

/* Active link style */
.active {
  color: #116a47;
}

/* Other styles as needed */
